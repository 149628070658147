<style lang="scss" scoped>
/deep/ .comp-asset-user-edit {
    margin-top: 6vh !important;
    max-width: 800px;
}
</style>

<template>
    <div>
        <el-dialog title="使用者编辑" custom-class="c-el-dialog comp-asset-user-edit" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="130px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="所属事业部">
                            <el-select class="mrgr5 mrgb5" :class="$root.isPc ? 'c-pw60' : 'c-pw100'" placeholder="请选择所属事业部" size="medium" filterable
                                clearable v-model="dialogData.companyId" :disabled="dialogType === createOrUpdate[1]">
                                <el-option v-for="ws in allCompany" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                            </el-select>
                            <el-button type="warning" size="medium" plain v-if="dialogType === createOrUpdate[0]"
                                @click="addCompany()">新增事业部</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="选择分类" prop="categoryId">
                            <el-cascader :ref="refCascader" class="mrgr5 mrgb5" :class="$root.isPc ? 'c-pw60' : 'c-pw100'" popper-class="c-no-cascader-radio" v-model="dialogData.categoryId" :options="allAssetUserCategory" placeholder="请选择使用者分类" 
                                :props="defaultProps" filterable @change="closeCascader"></el-cascader>
                            <el-button type="primary" size="medium" plain v-if="dialogType === createOrUpdate[0]"
                                @click="openAddType()">新增分类</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="使用者名称" prop="name">
                            <el-input :class="$root.isPc ? 'c-pw60' : 'c-pw100'" v-model="dialogData.name" placeholder="请输入使用者名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="联系方式">
                            <el-input :class="$root.isPc ? 'c-pw60' : 'c-pw100'" v-model="dialogData.phone" placeholder="请输入使用者联系方式"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" @click="_save()">提交</el-button>
            </span>
        </el-dialog>

        <!--新增使用者分类-->
        <type-edit :ref="refCategoryEdit" @refreshData="refreshCategory"></type-edit>
        <!--新增事业部-->
        <company :ref="refCompany" @refreshData="refreshCompany"></company>
    </div>
</template>

<script>
import * as funInfomation from "@/api/information"
import configMixins from "@/mixins/config"
import TypeEdit from "./typeEdit"
import company from "@/views/admin/company/components/edit"
export default {
    name: "compAssetUserEdit",
    mixins: [configMixins],
    components: { TypeEdit, company },
    data() {
        return {
            refCascader: "refAssetUserEditCascader",
            refCategoryEdit: "refAssetUserEditToCategoryEdit",
            refCompany: "refAssetUserEditToCompany",
            showDialog: false,
            dialogData: {},
            defaultDialogData: {
                name: "",
                companyId: "",
                categoryId: ""
            },
            dialogType: "",
            formRefName: "refAssetUserEdit",
            formRules: {
                categoryId: [{ required: true, message: '请选择使用者分类', trigger: ['blur', 'change']}],
                name: [{ required: true, message: '请输入使用者名称', trigger: 'blur'}]
            },
            allAssetUserCategory: [],
            allCompany: [],
            defaultProps: {
                value: "id",
                children: "children",
                label: "name",
                expandTrigger: "hover",
                checkStrictly: true,
                emitPath: false
            }
        }
    },
    methods: {
        open(row) {
            this.getAllAssetUserCategory()
            this.getAllCompany()
            this.dialogData = JSON.parse(JSON.stringify(this.defaultDialogData))
            if (row) {
                this.dialogType = this.createOrUpdate[1]
                // 编辑
                window.$common.fullLoading()
                funInfomation.getAssetUserDetail({id: row.id}).then(res => {
                    window.$common.closeFullLoading()
                    this.dialogData = res
                    this.dialogData.companyId = res.company && res.company.id || ""
                    this.dialogData.categoryId = res.category && res.category.id || null
                    this.showDialog = true
                })
            } else {
                this.dialogType = this.createOrUpdate[0]
                this.showDialog = true
            }
        },
        async getAllAssetUserCategory(){
            window.$common.fullLoading()
            await funInfomation.getAssetUserCategory().then(res => {
                window.$common.closeFullLoading()
                this.allAssetUserCategory = this.parseCategoryTree(res)
            })
        },
        async getAllCompany(){
            window.$common.fullLoading()
            await funInfomation.searchCompany().then(res => {
                window.$common.closeFullLoading()
                this.allCompany = res.items
            })
        },
        parseCategoryTree(datas) {
            let trees = []
            for(let i in datas) {
                let temp = datas[i]
                if (datas[i].children && datas[i].children.length > 0) {
                    temp.children = this.parseCategoryTree(datas[i].children)
                } else {
                    delete temp.children
                }
                trees.push(temp)
            }
            return trees
        },
        setPhoto(item) {
            this.dialogData.imageUrl = item.url || ""
        },
        // 打开添加分类
        openAddType() {
            this.$refs[this.refCategoryEdit].open()
        },
        // 添加分类后重新加载
        async refreshCategory(data) {
            await this.getAllAssetUserCategory()
            if (data && data.id) {
                this.dialogData.categoryId = data.id
                this.$forceUpdate()
            }
        },
        addCompany() {
            this.$refs[this.refCompany].open()
        },
        async refreshCompany(data) {
            await this.getAllCompany()
            if (data && data.id) {
                this.dialogData.companyId = data.id
                this.$forceUpdate()
            }
        },
        _save() {
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    let commitData = JSON.parse(JSON.stringify(this.dialogData))
                    window.$common.fullLoading()
                    if (this.dialogType == this.createOrUpdate[0]) {
                        // 创建
                        funInfomation.setAssetUser(commitData).then((res) => {
                            this.commonSuccess("操作成功!", res)
                        })
                    } else {
                        // 修改
                        funInfomation.updateAssetUser(commitData).then((res) => {
                            this.commonSuccess("操作成功!", res)
                        })
                    }
                } else {
                    return false
                }
            })
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        closeCascader() {
            this.$refs[this.refCascader].dropDownVisible = false
        },
        commonSuccess(val, data) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                this._close()
                window.$common.closeFullLoading()
                this.refreshData(data)
            })
        },
        refreshData(data) {
            this.$emit("refreshData", data)
        }
    }
}
</script>